/**
 * @file    index.tsx - Exports an component which renders the web app's home
 *          page.
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Apr. 2023
 */

import { useEffect, useState } from 'react';

import { Dashboard } from '@/features/misc';

/**
 * Renders the home page.
 *
 * @returns The rendered page.
 */
export default function HomePage(): JSX.Element {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? <Dashboard /> : <></>;
}

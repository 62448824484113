/**
 * @file    comboBox.tsx
 */

'use client';
import { useEffect, useRef, useState } from 'react';

import { debugLog } from '@/lib/debug';

import { ComboBoxDialog } from './comboBoxDialog';

interface BankAccountInputProps {
  worker: any;
}

/**
 *
 * @param props
 * @param props.worker
 * @returns
 */
export function ComboBox({ worker }: BankAccountInputProps): JSX.Element {
  const [openModal, setOpenModal] = useState(false);
  const [targetCores, setTargetCores] = useState(
    worker.workerOptions.cores.cpu,
  );
  const [numberOfMaxCores] = useState(
    window.navigator.hardwareConcurrency || 128,
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  const cores = Array.from({ length: numberOfMaxCores }, (_, i) => i + 1);

  useEffect(() => {
    if (worker.workerOptions.cores.cpu === 0) {
      // `window.navigator.hardwareConcurrency` not supported.
      worker.workerOptions.cores.cpu = 1;
    }
  }, [worker.workerOptions.cores]);

  const handleSelectModal = ({ selectCores }: { selectCores: string }) => {
    setOpenModal(false);
    debugLog({ selectCores });
    const newCores = parseInt(selectCores);
    setTargetCores(newCores);
    if (worker?.workerOptions) {
      worker.workerOptions.cores.cpu = newCores;
    }
  };

  return (
    <>
      <div className="hidden xl:block w-[100%] relative mt-2" ref={dropdownRef}>
        {/* Dropdown Selected */}
        <button
          className="flex text-lg ring-offset-background file:border-0 file:bg-transparent font-source-code text-[18px] text-ellipsis whitespace-nowrap overflow-hidden focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-white font-bold bg-[#ffffff33] w-[100%] py-[8px] px-[20px]"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {targetCores || 'Choose # CPU Cores'}
        </button>
      </div>
      <div className="w-[100%] flex justify-end items-center gap-[7px]">
        <label className="block xl:hidden font-source-code text-[18px] text-ellipsis whitespace-nowrap w-[8rem] xl:w-[100%] overflow-hidden text-right">
          {targetCores || 'Choose # CPU Cores'}
        </label>
        {/* Mobile + */}
        <button
          className="block xl:hidden text-[24px] font-[600] text-[#6CCA98]"
          onClick={() => setOpenModal(true)}
        >
          +
        </button>
      </div>
      <ComboBoxDialog
        show={openModal}
        setShow={setOpenModal}
        onSubmit={handleSelectModal}
        targetCores={targetCores}
        cores={cores}
      />
    </>
  );
}

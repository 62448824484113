/**
 * @file    title - for component titles
 *
 *
 * @author  Kevin Yu
 * @date    Nov. 2023
 */

import * as LabelPrimitive from '@radix-ui/react-label';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

const Title = ({ children, className }: any) => {
  return (
    <h3
      className={twMerge(
        'font-bold text-[18px] .dcp-text-col mb-[15px]',
        className,
      )}
    >
      {children}
    </h3>
  );
};

Title.displayName = LabelPrimitive.Root.displayName;

export { Title };

/**
 * @file    input.tsx - Displays a form input field or a component that looks
 *          like an input field.
 *
 *          Generated from `shadcn/ui`: https://ui.shadcn.com/
 *
 * @author  Bryan Hoang <bryan@distributve.network>
 * @date    Nov. 2023
 */

import { forwardRef, type InputHTMLAttributes } from 'react';

import { cn } from '@/lib/utils';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        spellCheck={false}
        className={cn(
          'flex h-10 w-full bg-background text-lg ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 text-white font-bold bg-[#ffffff33] w-[100%] py-[10px] px-[20px] border border-solid border-border',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export { Input };

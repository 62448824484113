/**
 * @file    CPUCoresForm.tsx - TODO:
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @author  David Courtis <david@distributive.network>
 * @date    Nov. 2023
 */

import { valibotResolver } from '@hookform/resolvers/valibot';
import type Worker from 'dcp/worker';
import { useForm } from 'react-hook-form';
import { number, object, type Output } from 'valibot';

import { ComboBox } from '@/components/ui/comboBox';
import { Title } from '@/components/ui/title';
import { debugLog } from '@/lib/debug';

interface BankAccountInputProps {
  worker: Worker;
}

const cpuCoresSchema = object({
  cpuCores: number(),
});

/**
 * CPU Cores Block
 *
 * @param props
 * @param props.worker - the worker instance
 *
 * @returns the rendered component
 */
function CPUCoresForm({ worker }: BankAccountInputProps): JSX.Element {
  const form = useForm<Output<typeof cpuCoresSchema>>({
    resolver: valibotResolver(cpuCoresSchema),
    reValidateMode: 'onSubmit',
    defaultValues: {
      cpuCores: worker.workerOptions.cores.cpu,
    },
  });

  function onSubmit(values: Output<typeof cpuCoresSchema>) {
    debugLog('CPUCoresForm values:', values);
  }

  return (
    <>
      <div className="flex flex-row items-center xl:items-start justify-between xl:justify-start xl:flex-col">
        <Title className="w-[100%] mb-[0] xl:mb-[10px] text-base xl:text-xl dcp-text-col font-bold">
          CPU Cores
        </Title>
        <ComboBox worker={worker} />
      </div>
    </>
  );
}

export { CPUCoresForm };

/**
 * @file    JoinComputeGroup.tsx - A component that deals with the logic for
 *          joining a compute group.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Nov. 2023
 */

import Worker from 'dcp/worker';
import { useEffect, useState } from 'react';
import { type SubmitHandler } from 'react-hook-form';
import type { Output } from 'valibot';

import { Button } from '@/components/ui/button';
import { Title } from '@/components/ui/title';
import { BASE_PATH, DEFAULT_JOIN_KEY } from '@/config';
import {
  ComputeGroupJoinDetails,
  JoinCredentialDialog,
  JoinKeyForm,
  joinKeySchema,
} from '@/features/compute-group';
import { debugLog } from '@/lib/debug';

interface ComputeGroupProps {
  worker: Worker;
}

/**
 * Renders a form and dialog component for joining a compute group.
 *
 * @param props - Component properties.
 * @param props.worker - An instance of a DCP Worker that will join the compute group.
 * @returns The rendered component.
 */
function JoinComputeGroup({ worker }: ComputeGroupProps): JSX.Element {
  const [isJoinCredentialDialogVisible, setIsJoinCredentialDialogVisible] =
    useState(false);
  const [currentJoinKey, setCurrentJoinKey] = useState(DEFAULT_JOIN_KEY);

  /**
   * Tries to detect if a join key has been specified from path name or query
   * string. e.g., `/demo` or `/?joinKey=demo`.
   */
  useEffect(() => {
    /**
     * Workaround for different pathnames in development vs. staging vs.
     * production. e.g., GitLab Pages or review apps using previewable artifacts
     * from jobs as the `BASE_PATH`.
     *
     * Ignore `index.html` as a potential compute group name to better support
     * previewing through previewable job artifacts on GitLab. If someone names
     * a compute group 'index.html', then they should use something other than
     * dcp.work!
     */
    const joinKeyRegex = new RegExp(
      `${BASE_PATH}/(?:(?!index.html)(?<newJoinKey>.*)?)?`,
    );
    let newJoinKey: string;

    debugLog('Default join key:', DEFAULT_JOIN_KEY);
    debugLog('Join Key Regex:', joinKeyRegex);

    newJoinKey =
      window.location.pathname.match(joinKeyRegex)!.groups!['newJoinKey'] ??
      DEFAULT_JOIN_KEY;

    debugLog('Join key after inferring from pathname:', newJoinKey);

    // Try query string to support testing on previewable GitLab CI artifacts.
    if (newJoinKey === DEFAULT_JOIN_KEY) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      newJoinKey = urlSearchParams.get('joinKey') ?? DEFAULT_JOIN_KEY;
      debugLog('Join key inferred from search parameter:', {
        newJoinKey,
      });
    }

    const isJoiningComputeGroup = newJoinKey !== DEFAULT_JOIN_KEY;
    setCurrentJoinKey(newJoinKey);
    setIsJoinCredentialDialogVisible(isJoiningComputeGroup);
  }, []);

  useEffect(() => {
    if (!(currentJoinKey !== DEFAULT_JOIN_KEY) && worker) {
      worker.workerOptions.computeGroups = [];
      worker.workerOptions.leavePublicGroup = false;
    }
  }, [worker, currentJoinKey]);

  const setComputeGroupToJoin: SubmitHandler<{
    joinKey: string;
    joinCredential: string;
  }> = ({ joinKey, joinCredential }) => {
    let computeGroupToJoin: ComputeGroupJoinDetails;

    if (!joinKey) {
      joinKey = DEFAULT_JOIN_KEY;
    }

    if (joinCredential.startsWith('eh1-')) {
      computeGroupToJoin = {
        joinKey,
        joinHash: joinCredential,
      };
    } else {
      computeGroupToJoin = {
        joinKey,
        joinSecret: joinCredential,
      };
    }

    debugLog({ computeGroupToJoin });

    worker.workerOptions.computeGroups = [computeGroupToJoin];
    worker.workerOptions.leavePublicGroup = true;

    setCurrentJoinKey(joinKey);
    setIsJoinCredentialDialogVisible(false);
  };

  return (
    <div className="flex flex-row items-center xl:items-start justify-between xl:justify-start xl:flex-col">
      <Title className="w-[100%] mb-[0] xl:mb-[10px] text-base xl:text-xl dcp-text-col font-bold">
        Compute Group
      </Title>
      <div className="hidden xl:block w-[100%] relative mt-2">
        <button
          className="inline-flex text-lg ring-offset-background file:border-0 file:bg-transparent font-source-code text-[18px] text-ellipsis whitespace-nowrap overflow-hidden focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-white font-bold bg-[#ffffff33] w-[100%] py-[8px] px-[20px]"
          onClick={() => {
            setIsJoinCredentialDialogVisible(true);
          }}
        >
          <span className="text-ellipsis overflow-hidden">
            {currentJoinKey.toString()}
          </span>
        </button>
      </div>
      <div className="w-[100%] flex justify-end items-center gap-[7px]">
        <label className="block xl:hidden font-source-code text-[18px] text-ellipsis whitespace-nowrap w-[8rem] xl:w-[100%] overflow-hidden text-right">
          {currentJoinKey.toString()}
        </label>
        <button
          className="block xl:hidden text-[24px] font-[600] text-[#6CCA98]"
          onClick={() => {
            setIsJoinCredentialDialogVisible(true);
          }}
        >
          +
        </button>
      </div>
      <JoinCredentialDialog
        show={isJoinCredentialDialogVisible}
        setShow={setIsJoinCredentialDialogVisible}
        onSubmit={setComputeGroupToJoin}
      />
    </div>
  );
}

export { JoinComputeGroup };

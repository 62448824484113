/**
 * @file    JoinKeyForm.tsx - A form for accepting join keys of a compute group.
 *
 * @author  Bryan Hoang <bryan@distributve.network>
 * @date    Nov. 2023
 */

import 'react-toastify/dist/ReactToastify.css';

import { valibotResolver } from '@hookform/resolvers/valibot';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import { object, type Output, string } from 'valibot';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export const joinKeySchema = object({
  joinKey: string(),
});

interface ComputeGroupInputProps {
  joinKey: string;
  onSubmit: SubmitHandler<Output<typeof joinKeySchema>>;
}

/**
 * Renders a form to allow inputing a join key.
 *
 * @param props - Component properties.
 * @param props.joinKey - The currently selected join key.
 * @param props.onSubmit
 * @returns The rendered component.
 */
export function JoinKeyForm({
  joinKey,
  onSubmit,
}: ComputeGroupInputProps): JSX.Element {
  const form = useForm<Output<typeof joinKeySchema>>({
    resolver: valibotResolver(joinKeySchema),
    defaultValues: {
      joinKey,
    },
  });

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="joinKey"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center xl:items-start justify-between xl:justify-start xl:flex-col">
                <FormLabel className="w-[100%] text-base dcp-text-col font-bold flex flex-col">
                  Compute Group
                </FormLabel>
                <div className=" w-[100%] flex justify-end items-center gap-[7px]">
                  <FormLabel className="xl:bg-[#ffffff33] xl:w-[100%] text-[18px] relative font-[600] xl:py-[8px] pl-[19px]">
                    {field.value}
                  </FormLabel>
                  <button className="block xl:hidden text-[24px] font-[600] text-[#6CCA98]">
                    +
                  </button>
                </div>
              </FormItem>
            )}
          />
          <div className="w-[100%] hidden xl:flex justify-end mt-[1.6rem]">
            <Button variant="secondary" type="submit">
              CHANGE
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}

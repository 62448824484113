/**
 * @file    WorkAnimation.tsx - Exports a component that renders an animation
 *          based on either the worker is working.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Nov. 2023
 */

import Image from 'next/image';

import GLOBE from '@/assets/gifs/globe.gif';
import MATRIX from '@/assets/gifs/matrix.gif';
import DCP_LOGO from '@/assets/img/run_dcp_logo.png';

interface WorkAnimationProps {
  isRunning: boolean;
}

/**
 * Renders a work animation when the worker is running.
 *
 * @param props - Component properties.
 * @param props.isRunning - Whether the worker is currently running.
 * @returns The rendered component.
 */
function WorkAnimation({ isRunning }: WorkAnimationProps) {
  return (
    <>
      <div
        className={`flex relative justify-center items-center h-[100%] w-[100%] transition-transform duration-500 ease-in-out ${
          isRunning
            ? 'translate-y-[-22px] xl:translate-y-[-35px] scale-[0.75]'
            : ''
        }`}
      >
        {/* TODO(bryan-hoang): Figure determine if fancy animations will be used. */}
        {/* <Image
          className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
          src={MATRIX}
          height={648}
          width={645}
          alt="Matrix"
        />
        <Image
          className="absolute opacity-[10%]"
          src={GLOBE}
          height={344}
          width={344}
          alt="globe"
        /> */}
        <Image
          className="z-[1] h-[80px] xl:h-[120px] w-auto"
          src={DCP_LOGO}
          alt="DCP"
        />
      </div>
    </>
  );
}

export { WorkAnimation };

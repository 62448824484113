/**
 * @file    DepositAccountForm.tsx - Exports a form component for entering
 *          a bank account to deposit credits into.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Sept. 2023
 */
import { useState } from 'react';
import { object, type Output, string } from 'valibot';

import { Title } from '@/components/ui/title';
import { AccountModal } from '@/features/account/components/AccountModal';
import { debugLog } from '@/lib/debug';

interface BankAccountInputProps {
  worker: any;
  defaultDepositAccount: string;
  depositAccount: string | Address | Error;
  setDepositAccount: (address: string) => void;
}

const depositAccountSchema = object({
  depositAccount: string(),
});

/**
 * Renders a form component for entering a bank account.
 *
 * The form's input value must be synced the worker's payment bank account as a
 * side effect.
 *
 * @param props - Component properties.
 * @param props.worker - The instance of the worker, whose payment account we need to stay in sync with.
 * @param props.defaultDepositAccount - The default value used for the input.
 * @param props.depositAccount - The default value used for the input.
 * @param props.setDepositAccount - The setter for the bank account state.
 * @returns The rendered component.
 */
export function DepositAccountForm({
  worker = { workerOptions: { paymentAddress: '' } },
  defaultDepositAccount,
  depositAccount,
  setDepositAccount,
}: BankAccountInputProps): JSX.Element {
  const [modal, setModal] = useState(false);

  function onSubmit(values: Output<typeof depositAccountSchema>) {
    debugLog('DepositAccountForm values:', values);
    if (values.depositAccount === '') {
      setDepositAccount(defaultDepositAccount);
    } else {
      setDepositAccount(values.depositAccount);
    }
    setModal(false);
  }

  return (
    <div className="flex flex-row items-center xl:items-start justify-between xl:justify-start xl:flex-col">
      <Title className="w-[100%] mb-[0] xl:mb-[10px] text-base xl:text-xl dcp-text-col font-bold">
        Deposit Account
      </Title>
      <div className="hidden xl:block w-[100%] relative mt-2">
        <button
          className="flex text-lg ring-offset-background file:border-0 file:bg-transparent font-source-code text-[18px] text-ellipsis whitespace-nowrap overflow-hidden focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ri  ng-offset-2 disabled:cursor-not-allowed disabled:opacity-50 text-white font-bold bg-[#ffffff33] w-[100%] py-[8px] px-[20px]"
          onClick={() => {
            setModal(true);
          }}
        >
          <span className="text-ellipsis overflow-hidden">
            {depositAccount.toString()}
          </span>
        </button>
      </div>
      <div className="w-[100%] flex justify-end items-center gap-[7px]">
        <label className="block xl:hidden font-source-code text-[18px] text-ellipsis whitespace-nowrap w-[8rem] xl:w-[100%] overflow-hidden text-right">
          {depositAccount.toString()}
        </label>
        <button
          className="block xl:hidden text-[24px] font-[600] text-[#6CCA98]"
          onClick={() => {
            setModal(true);
          }}
        >
          +
        </button>
      </div>
      <AccountModal
        worker={worker}
        depositAccount={depositAccount}
        defaultDepositAccount={defaultDepositAccount}
        show={modal}
        setShow={setModal}
        onSubmit={onSubmit}
      />
    </div>
  );
}

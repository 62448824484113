/**
 * @file    ReadySpinner.tsx - An animated spinner indicating sandboxes that are
 *          ready for work.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Feb. 2024
 */

import Image from 'next/image';

import matrix1 from '@/assets/img/matrix-1.png';
import matrix2 from '@/assets/img/matrix-2.png';
import matrix3 from '@/assets/img/matrix-3.png';

/**
 * Renders an animated spinner.
 *
 * @returns The rendered component.
 */
export function ReadySpinner(): JSX.Element {
  return (
    <>
      <div className="relative w-[80px] h-[80px]">
        <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ">
          {/* Matrix 1 */}
          <div className="w-[50px] h-[50px] relative">
            <Image
              src={matrix3}
              alt="Matrix"
              fill
              className="animate-matrix-spin-1 -[-80deg]"
            />
          </div>
        </div>
        {/* Matrix 2 */}
        <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="w-[38px] h-[38px] relative">
            <Image
              src={matrix1}
              alt="Matrix"
              fill
              className="animate-matrix-spin-2 -[30deg]"
            />
          </div>
        </div>
        {/* Matrix 3 */}
        <div className=" absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <div className="w-[29px] h-[29px] relative">
            <Image
              src={matrix2}
              alt="Matrix"
              fill
              className="animate-matrix-spin-3 -[-50deg]"
            />
          </div>
        </div>
        {/* Ellipses */}
        <span className="ellipses absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] !text-[25px] h-[10px] flex justify-center items-center text-center"></span>
      </div>
    </>
  );
}

/**
 * @file    RunDCP.tsx - A card that deals with worker status and management
 * @author  David Courtis <david@distributive.network>
 *          Kevin Yu <bryan@distributive.network>
 * @date    Nov. 2023
 */

import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Title } from '@/components/ui/title';
import { SandboxAnimation, WorkAnimation } from '@/features/worker';
import { debugLog } from '@/lib/debug';
import { cn } from '@/lib/utils';

interface RunDCPProps {
  worker: any;
  workerState: any;
}

/**
 * Compute Group Block
 *
 * @param props
 * @param props.worker
 * @param props.workerState
 * @returns the rendered component
 */
function RunDCP({ worker, workerState }: RunDCPProps): JSX.Element {
  const [workerStatusText, setWorkerStatusText] = useState(
    'Run DCP jobs to earn Compute Credits',
  );

  function toggleWorker() {
    if (workerState.working) {
      worker.stop(true).catch((error: any) => {
        console.error('Stopping the worker threw an unexpected error:', error);
      });
    } else {
      worker
        .start()
        .catch((error: any) =>
          console.error(
            'Starting the worker threw an unexpected error:',
            error,
          ),
        );
    }
  }

  useEffect(() => {
    debugLog('Sandboxes:', worker.sandboxes);
  }, [worker.sandboxes]);

  useEffect(() => {
    const onFetch = (event: any) => {
      debugLog('Worker fetched:', event);
      if (typeof event.jobs !== 'object') {
        throw event;
      }

      const fetchedJobCount = Object.keys(event.jobs).length;

      // Is the worker fetching work while idling?
      if (fetchedJobCount === 0 && worker.workingSandboxes.length === 0) {
        setWorkerStatusText('Waiting for Work');
      } else if (fetchedJobCount !== 0) {
        setWorkerStatusText('WORKING');
      }
    };

    worker.on('fetch', onFetch);

    return () => {
      worker.off('fetch', onFetch);
    };
  }, [worker]);

  useEffect(() => {
    if (!worker.working) {
      setWorkerStatusText('Run DCP jobs to earn Compute Credits');
    } else if (worker.sandboxes.length === 0) {
      setWorkerStatusText('FETCHING');
    } else {
      setWorkerStatusText('WORKING');
    }
  }, [worker, worker.working, worker.sandboxes.length]);

  return (
    <>
      <Card className="flex flex-col pt-[15px] xl:pt-[30px] pb-[10px] xl:pb-[30px] gap-[30px] xl:gap-[60px] justify-start items-center min-h-[320px] xl:min-h-[660px] relative transition-all">
        {/* TITLE */}
        <div className="flex items-center justify-center z-[1]">
          {/* Fetching/Working */}

          <div className="flex h-[auto] items-center relative">
            <Title className="self-end text-base xl:text-xl font-bold text-center mb-0 !leading-none">
              {workerStatusText}
            </Title>
            {worker.working && (
              <div className="ellipses text-[30px] absolute right-[0] translate-x-[110%] "></div>
            )}
          </div>
        </div>

        {/* DCP LOGO */}
        <div className="flex flex-col gap-[30px] xl:gap-[60px] xl:pb-[0px] justify-center items-center w-[100%]">
          <WorkAnimation isRunning={worker.working} />

          {/* Start and Stop */}
          <Button
            className={cn(
              `z-[1] transition-all duration-500 ease-in-out`,
              { 'bg-[#1AA473] hover:bg-[#1AA473]/90': !worker.working },
              {
                'translate-y-[-40px] xl:translate-y-[-65px] bg-secondary/40 hover:bg-secondary/70 scale-[80%]':
                  worker.working,
              },
            )}
            onClick={(_event) => {
              toggleWorker();
            }}
          >
            {worker.working ? 'STOP' : 'START'}
          </Button>
        </div>

        {/* Workspaces */}
        {worker.working && (
          <div
            className={cn(
              `grid grid-cols-desktopSandboxes lg:grid-row-auto grid-flow-col md:grid-flow-dense lg:auto-cols-auto items-start justify-center gap-[12px] md:gap-[25px] xl:gap-[30px] mt-[-63px] overflow-auto xl:overflow-hidden w-[100%] max-h-[400px] lg:w-[65vw] xl:w-[90%] pb-[10px]`,
              { 'min-h-[113px] lg:min-h-auto': worker.working },
              {
                'justify-start md:justify-center': worker.sandboxes.length > 2,
              },
            )}
          >
            {worker.sandboxes.map((sandbox: any) => (
              <SandboxAnimation key={sandbox.id} sandbox={sandbox} />
            ))}
          </div>
        )}
      </Card>
    </>
  );
}

export { RunDCP };

/**
 * @file    Balance.tsx - Renders a component which returns the balance text to
 *                        a fixed decimal place.
 * @author  Jordan Cartlidge <jordan@distributive.network>
 * @author  Joash Mathew <joash@distributive.network>
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    June 2022, September 2022, March 2023
 */

import BigNumber from 'bignumber.js';
import Image from 'next/image';

import creditsIcon from '@/assets/img/credits-green.png';

import styles from './Balance.module.css';

type BalanceProps = {
  balance: BigNumber;
  className?: string;
};

/**
 * Renders a component showing the balance padded to a set number of decimal
 * points.
 *
 * @param props - The component's properties.
 * @param props.balance - The DCC amount to render.
 * @param props.className - names to add to the underlying component.
 * @returns The DCC balance fixed to a set number of decimal points.
 */
export function Balance({
  balance,
  className = '',
}: BalanceProps): JSX.Element {
  // Runtime check for a valid BigNumber value.
  if (!BigNumber.isBigNumber(balance) || balance.isNaN()) {
    return <span>Unable to display credits</span>;
  }

  const balanceString = balance.toFixed(3);
  const [nonFractional, fractional] = balanceString.split('.') as [
    string,
    string,
  ];
  const language = window.navigator.language;
  let formattedString = '';

  /**
   * Work around edge case in rendering large values. If it's too large, split
   * up the non-fractional portion and ignore formatting the portion > 16 digits
   * long.
   */
  if (
    Number.isSafeInteger(Number(nonFractional)) ||
    nonFractional.length <= 16
  ) {
    formattedString += `${new Intl.NumberFormat(language).format(
      Number(nonFractional),
    )}.${fractional}`;
  } else {
    const prefix = nonFractional.slice(0, -16);
    const formattable = nonFractional.slice(-16, -1);
    const formatted = new Intl.NumberFormat(language, {
      minimumIntegerDigits: 16,
    }).format(Number(formattable));

    formattedString += `${prefix}${formatted}.${fractional}`;
  }

  return (
    <span className={`${styles['dcc']} ${className}`}>{formattedString}</span>
  );
}

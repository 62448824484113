/**
 * @file    SlicesComputed.tsx - Exports a card component that displays the
 *          count of slices completed.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @author  David Courtis <david@distributive.network>
 * @date    Nov. 2023
 */

import { Card } from '@/components/ui/card';
import { Dots } from '@/components/ui/dots';
import { Title } from '@/components/ui/title';

type WorkerStatsProps = {
  totalSlicesDone: number;
  workerOn: boolean;
};

/**
 * Renders a card component that displays the total number of slices completed.
 *
 * @param props - Component properties.
 * @param props.totalSlicesDone - The count of slices completed.
 * @param props.workerOn - The state of the worker on or off.
 * @returns The rendered component.
 */
function SlicesComputed({ totalSlicesDone, workerOn }: WorkerStatsProps) {
  return (
    <>
      <Card className="flex xl:flex-col justify-between items-center py-[0px] px-[30px] xl:p-[30px] gap-[20px]">
        <Title className="mb-[0] xl:mb-[10px] text-base xl:text-xl dcp-text-col font-bold w-[100%]">
          Slices Completed
        </Title>
        {/* TODO: fix absolute to relative. */}
        <div className="flex w-[100%] gap-[6px] justify-end items-center xl:absolute xl:left-[-30px] xl:right-0 xl:top-0 xl:bottom-0 xl:flex xl:items-center font-[400] text-[20px] flex-wrap font-source-code">
          <p>{totalSlicesDone.toLocaleString()}</p>
        </div>
      </Card>
    </>
  );
}

export { SlicesComputed };

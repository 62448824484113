/**
 * @file    CreditsEarned.tsx - Exports a card component that displays the
 *          total amount of DCC earned.
 *
 * @author  Kevin Yu <yu.kevin2002@gmail.com>
 * @date    Nov. 2023
 */

import BigNumber from 'bignumber.js';
import Image from 'next/image';

import CREDIT from '@/assets/img/dcp-credit-symbol.svg';
import { Card } from '@/components/ui/card';
import { Dots } from '@/components/ui/dots';
import { Title } from '@/components/ui/title';
import { Balance } from '@/features/account';

type WorkerStatsProps = {
  totalCreditsEarned: BigNumber;
  workerOn: boolean;
};

/**
 * Renders a card that displays the total amount of DCCs earned.
 *
 * @param props - Component properties.
 * @param props.totalCreditsEarned - The amount of DCCs earned.
 * @param props.workerOn - The state of the worker on or off.
 * @returns The rendered component.
 */
function CreditsEarned({ totalCreditsEarned, workerOn }: WorkerStatsProps) {
  return (
    <>
      <Card className="flex xl:flex-col justify-start items-start pt-[15px] xl:pt-[30px] pb-[0px] px-[30px] xl:p-[30px] ">
        {/* <Dots workerOn={workerOn} dotClass={'ui-dots-container-bottom'} /> */}
        <Title className="mb-[0] xl:mb-[10px] text-base xl:text-xl dcp-text-col font-bold w-[100%]">
          Credits Earned
        </Title>
        {/* TODO: fix absolute to relative. */}
        <div className="flex w-[100%] gap-[6px] justify-end items-center xl:absolute xl:left-[-30px] xl:right-0 xl:top-0 xl:bottom-0 xl:flex xl:items-center font-[400] text-[20px] flex-wrap font-source-code">
          <Balance balance={totalCreditsEarned} />
          <Image
            src={CREDIT}
            alt="credit"
            height={18}
            className="relative top-[1px]"
          />
        </div>
      </Card>
    </>
  );
}

export { CreditsEarned };

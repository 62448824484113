/**
 * @file    debug.ts - Exports a logging function to use for debugging.
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Aug. 2021
 */

import createDebug from 'debug';

// Debugging logs to clean up output on production
export const debugLog = createDebug('dcp.work');

import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { cn } from '@/lib/utils';

import Loader from '../../assets/img/sandbox-loader.png';
/**
 *
 * @param props
 * @param props.progress
 */
function Spinner({ progress }: any) {
  const radius = 27; // Same as your SVG circle's radius
  const circumference = 2 * Math.PI * radius;
  const curProgress = progress ?? 0;
  // Calculate the stroke dash offset based on progress
  const strokeDashoffset = ((100 - curProgress) / 100) * circumference;

  return (
    <div className="relative">
      <div className="relative rotate-[-90deg]">
        <div
          className={cn(
            `transition-all duration-1000 rounded-[50%] opacity-0 h-[52px] w-[52px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`,
            { 'bg-[#1aa473] opacity-[1]': curProgress === 100 },
          )}
        ></div>

        <svg width={80} height={80} className="z-20">
          <circle
            cx="40"
            cy="40"
            r={radius}
            fill={'none'}
            stroke="#1aa473"
            strokeWidth="3.5"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            style={{ transition: ' 0.1s ease-out' }} // Smooth transition for the drawing effect
          />
        </svg>
      </div>

      <div
        className={cn(
          `absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]`,
        )}
      >
        {/* Spinner */}
        <div
          className={cn(`relative w-[45px] h-[45px] transition-all`, {
            'opacity-0': progress === 100,
          })}
        >
          <Image
            className="animate-spin"
            src={Loader}
            fill
            alt="loader"
          ></Image>
        </div>

        {/* Percentage */}
        {!(progress == null) && (
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex items-center justify-center text-[12px]">
            <span>{Math.floor(parseFloat(curProgress))}%</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Spinner;
